<template>
    <section class="pre_of_patient_wrp">
        <div class="pre_of_patient_header_wrp">
            <div class="row">
                <div class="col text-right"><button class="btn btn-success" @click="addPrescriptionPopupMethod">+
                        Add</button></div>
            </div>
        </div>
        <!-- PRESCRIPTION LIST CODE HERE -->
        <div class="prescription_list_item">
            <template v-if="prescriptionList">
                <template v-if="prescriptionListResult.length > 0">
                    <div class="prescription_list_item_inner" v-for="item in prescriptionListResult" :key="item.id">
                        <div class="row align-items-center">
                            <div class="col pr-0">
                                <h6 class="prescription_list_heading">{{item.created_at | LocalDateFormat}} at
                                    {{item.created_at | LocalTimeFormat}}</h6>
                            </div>
                            <div class="col-auto ml-auto text-right">
                                <button class="btn btn-info" @click="prescriptionInfoByIdPopupFun(item)">View</button>
                                <!-- <button class="btn btn-success ml-2" @click="recreatingPrescDataPopupOpen(item)">Update</button> -->
                            </div>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="no_data_section">
                        <div class="no_data_section_inner">
                            <h4>You don't have any prescription</h4>
                        </div>
                    </div>
                </template>
            </template>
            <loader v-else></loader>
        </div>
        <!-- PRESCRIPTION LIST CODE HERE -->
        <!-- ADD PRESCRIPTION POPUP CODE HERE -->
         <add-prescription-popup v-if="addPrescriptionPopup" :preOfPateintId="preOfPateintId" :preOfAppointmentId="preOfAppointmentId" :prescriptionName="prescriptionName"></add-prescription-popup>
        <!-- ADD PRESCRIPTION POPUP CODE HERE -->
        <!-- PRESCRIPTION INFO BY ID POPUP CODE HERE -->
         <prescription-info-popup v-if="prescriptionInfoByIdPopupState" :prescriptionInfoByIdData="prescriptionInfoByIdData"></prescription-info-popup>
        <!-- PRESCRIPTION INFO BY ID POPUP CODE HERE -->
         <prescriptionAddBlurbPopup v-if="prescriptionAddBlurbPopupState"></prescriptionAddBlurbPopup>
    </section>
</template>

<script>
/*eslint-disable */
import {bus} from '@/main';
import loader from '@/components/common/component-small-loader';
import addPrescriptionPopup from './add-prescription-popup.vue';
import prescriptionInfoPopup from './prescription-info-popup.vue';
import prescriptionAddBlurbPopup from './prescription-add-blurb-popup';

export default {
  name: 'pre-of-patient',
  components: {
    loader,
    'add-prescription-popup' : addPrescriptionPopup,
    'prescription-info-popup' : prescriptionInfoPopup,
    prescriptionAddBlurbPopup
  },
  props: ['preOfPateintId', 'preOfAppointmentId'],
  data () {
    return {
      addPrescriptionPopup:false,
      recreatingPrescData:null,
      recreatingPrescDataPopupState:false,
      prescriptionList:null,
      prescriptionListResult:[],
      prescriptionName:'',
      prescriptionNumber : ['', 'first', 'second', 'third', 'fourth', 'fifth', 'sixth', 'seventh', 'eighth', 'ninth','Tenth', 'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen','twenty'],
      prescriptionInfoByIdPopupState:false,
      prescriptionInfoByIdData:null,
      prescriptionAddBlurbPopupState:false
    }
  },
  computed: {

  },
  mounted () {
    this.getPrescriptionListAgainFun();
    bus.$on('recreatingPrescDataPopupStateBus', (data) => {
      this.recreatingPrescDataPopupState = data;
      this.getPrescriptionListAgainFun()
    });
    bus.$on('addPrescriptionPopupBus', (data) => {
      this.addPrescriptionPopup = data;
      this.getPrescriptionListAgainFun();
    });
    bus.$on('prescriptionInfoByIdPopupBus', (data) => {
      this.prescriptionInfoByIdPopupState = data;
    });
    bus.$on('prescriptionAddBlurbPopupStateBus', (data) => {
      this.prescriptionAddBlurbPopupState = data;
    });
  },
  methods: {
    recreatingPrescDataPopupOpen(data){
      this.recreatingPrescData = data
      this.recreatingPrescDataPopupState = true
      document.body.classList.add('body_right_scroll_remove')
    },
    getPrescriptionListAgainFun(){
      this.$store.dispatch('getPrescriptionListOfPatientMethod', {id:this.preOfAppointmentId}).then((res)=>{
        this.prescriptionList = res.data.data
        if(this.prescriptionList){
          this.prescriptionListResult = this.prescriptionList.results
          this.prescriptionName = this.prescriptionNumber[this.prescriptionListResult.length + parseInt(1)]
        }
      })
    },
    addPrescriptionPopupMethod(){
      this.prescriptionAddBlurbPopupState = true;
      document.body.classList.add('body_right_scroll_remove');
    },
    prescriptionInfoByIdPopupFun(data){
      this.prescriptionInfoByIdData = data;
      this.prescriptionInfoByIdPopupState = true;
      document.body.classList.add('body_right_scroll_remove');
    },
  }
}
</script>

<style lang="scss" scoped>
.pre_of_patient_wrp {
    .btn{border:none;outline:none;box-shadow:none;padding:6px 10px;line-height:1;border-radius:2px;min-width:66px;}
    .pre_of_patient_header_wrp{background: #272727;border-radius: 2px;padding:5px 10px;}
    .prescription_list_item{
        padding:10px 15px;
        height: calc(100vh - 295px);
        overflow-y: auto;
        &::-webkit-scrollbar {
        width:4px;
        background: none;
        }
        &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #2f2f2f;
        }
        .prescription_list_item_inner{
            border-bottom:1px solid rgba(148, 148, 148, 0.3);
            padding:10px 0;
            .prescription_list_heading{font-size:20px;font-weight:bold;color: #f1f1f1;margin:0px;font-family: 'simplicitymedium';letter-spacing:1px;
            @media only screen and (max-width:767px){font-size:16px;}}
            .btn{
                text-transform: uppercase;
                padding:5px 8px;
                line-height: 1;
                -webkit-box-shadow: none;
                box-shadow: none;
                border: none;
                border-radius: 3px;
            }
            .btn-success{    
                background: #ff3a30;
                color: #ffffff;
                &:hover{
                background: lightskyblue;
                color: #000000;
                }
            }
        }
        .no_data_section {
            padding-top: 100px;
            text-align: center;
            h4{font-weight:normal;font-size:20px;color:#ffffbe;}
            @media only screen and (max-width:767px){ padding-top: 20px;}
        }
        @media only screen and (max-width:767px){height:auto;}
    }
}
</style>