<template>
  <div class="patient_with_chat_box">
    <div class="patient_with_chat_box_inner_wrp">
      <div class="patient_with_chat_box_header">
        <div class="row align-items-center">
          <div class="col">
            <div class="row align-items-center" v-if="patientInfo">
              <div class="col-auto pr-3">
                <span class="consult_patient_avatar">
                  <span class="prfl_avatar_lazy_load_box">
                    <template v-if="patientInfo.user.profile.thumbnail">
                      <img :src="patientInfo.user.profile.thumbnail" class="img-fluid">
                      <img :src="patientInfo.user.profile.avatar" v-if="patientInfo.user.profile.avatar"
                        class="img-fluid">
                    </template>
                    <template v-else>
                      <img src="@/assets/images/user_default.png" class="img-fluid">
                    </template>
                  </span>
                </span>
              </div>
              <div class="col pl-0">
                <span class="consult_patient_name">{{ patientInfo.full_name }}</span>
              </div>
            </div>
          </div>
          <div class="col-auto text-right">
            <router-link :to="'/video/conference/#' + patientInfo.id" class="btn btn-primary mr-3">Join Meet</router-link>
            <button class="btn btn-danger" @click="chatClosedMethod">Close Chat</button>
          </div>
        </div>
      </div>
      <div class="patient_with_chat_box_body">
        <div class="patient_chat_msg_list_wrp" id="chat_msg_list_scroll">
          <div class="extra_pchat_space"></div>
          <div id="reply_msg_list" class="admin_msg_list">
            <div id="list_of_patient_chat_msg_wrp">
              <div class="list_msg_of_patient_chat">
                <ul>
                  <!-- <li class="msg_date_wrp" :class="'d' + msgDate">
                  <span class="msg_date_box">
                    <template v-if="enabledYearDateFun(msgDate)">{{msgDate | chatfulldateformat}}</template>
                    <template v-else>{{msgDate | chatdateformat}}</template>
                  </span>
                </li> -->
                  <li class="pchatmsg" v-for="item in patientChatDetailResults" :id="'chatMessageId' + item.id"
                    :key="item.id">
                    <div :class="item.sender.user_id == selfUserId ? 'pchatmsg_recieved' : 'pchatmsg_sent'">
                      <div class="pchat_msg_item"
                        :class="item.sender.user_id == selfUserId ? 'pchat_recieved_msg' : 'pchat_send_msg'">
                        {{ item.decrypted_content }}
                        <span class="msg_time_wrp" v-if="item">
                          <time v-if="item.timestamp" class="msg_time">{{ item.timestamp | chattimeformat }}</time>
                        </span>
                      </div>
                    </div>
                  </li>
                  <!-- <li >
                  <div class="pchat_msg_item pchat_recieved_msg">Recieved</div>
                </li> -->
                </ul>
              </div>
            </div>
          </div>
          <template v-if="typingUserInfoState">
            <div class="typing_dots_wrp" v-if="selfUserId == typingUserInfoState.targetForChatUserId"
              style="padding:0px 35px">
              {{ chatScrollBottomMethod() }}
              <img src="@/assets/images/loading_dots.gif" height="30">
            </div>
          </template>
          <div class="fixloader listLoading msgloader" v-if="msgApiLoader">
            <div class="spinner spinner-1"></div>
          </div>
        </div>
      </div>
      <div class="patient_with_chat_box_footer">
        <div class="message_input_wrp">
          <textarea type="text" autocomplete="off" id="chat-message-reply-input" placeholder="Type a message..."
            v-if="selection_type == 'click_send'" class="click_send"></textarea>
          <textarea type="text" autocomplete="off" id="chat-message-reply-input" placeholder="Type a message..."
            @keydown.enter.exact.prevent @keyup.enter.exact="send" @keydown.enter.shift.exact="newline" v-else
            class="press_enter"></textarea>
          <div class="option_btn_wrp">
            <button id="chat-message-reply-submit" class="submit"><svg width="57px" height="54px"
                viewBox="1496 193 57 54" version="1.1" xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink" style="width: 18px; height: 18px;">
                <g id="Group-9-Copy-3" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"
                  transform="translate(1523.000000, 220.000000) rotate(-270.000000) translate(-1523.000000, -220.000000) translate(1499.000000, 193.000000)">
                  <path data-v-77c07480=""
                    d="M5.42994667,44.5306122 L16.5955554,44.5306122 L21.049938,20.423658 C21.6518463,17.1661523 26.3121212,17.1441362 26.9447801,20.3958097 L31.6405465,44.5306122 L42.5313185,44.5306122 L23.9806326,7.0871633 L5.42994667,44.5306122 Z M22.0420732,48.0757124 C21.779222,49.4982538 20.5386331,50.5306122 19.0920112,50.5306122 L1.59009899,50.5306122 C-1.20169244,50.5306122 -2.87079654,47.7697069 -1.64625638,45.2980459 L20.8461928,-0.101616237 C22.1967178,-2.8275701 25.7710778,-2.81438868 27.1150723,-0.101616237 L49.6075215,45.2980459 C50.8414042,47.7885641 49.1422456,50.5306122 46.3613062,50.5306122 L29.1679835,50.5306122 C27.7320366,50.5306122 26.4974445,49.5130766 26.2232033,48.1035608 L24.0760553,37.0678766 L22.0420732,48.0757124 Z"
                    id="sendicon" fill="#ffffff" fill-rule="nonzero"></path>
                </g>
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable */
import axios from 'axios';
const $ = window.jQuery = require('jquery');
// import videoSocketRef from "@/videoNotificationSocket";
import {
  mapGetters
} from 'vuex';
import { bus } from '@/main';
import moment from 'moment';
var patientChatScoket;
export default {
  name: 'chat-of-patient',
  components: {},
  props: ['patientInfo'],
  data() {
    return {
      chatWithPatientId: '',
      patientChatDetailResults: [],
      selection_type: 'press_enter',
      checkingActvTabId: '',
      patientChatRoomId: '',
      selfUserInfo: null,
      typingUserInfoState: null,
      msgApiLoader: false,
      msgNextPageLoader: '',
      selfUserId: ''
    }
  },
  computed: {
  },
  mounted() {
    this.selfUserId = localStorage.getItem('LoginedUserID');
    this.patientChatRoomId = this.patientInfo.chat_room_id
    if (patientChatScoket && patientChatScoket.readyState) {
      patientChatScoket.close();
    }
    this.createRoomWithPatientMethod()
    bus.$on('TypingEventForSelectedUserBus', (data) => {
      this.typingUserInfoState = data
      if (data.message == 'stop') {
        this.typingUserInfoState = null;
      }
    })
    // Msg Loading Method Here
    var _self = this
    $('#chat_msg_list_scroll').on("scroll", function () {
      var yScrollHeight;
      var elmntList = document.getElementById("chat_msg_list_scroll");
      yScrollHeight = elmntList.scrollHeight
      if (0 == $('#chat_msg_list_scroll').scrollTop()) {
        if (_self.msgNextPageLoader) {
          _self.msgApiLoader = true
          axios.get(_self.msgNextPageLoader).then((response) => {
            _self.msgNextPageLoader = ''
            if (response.status == '200') {
              _self.msgNextPageLoader = response.data.data.next
              _self.msgApiLoader = false
              _self.patientChatDetailResults = [].concat(_self.patientChatDetailResults, response.data.data.results);
              // _self.chatRepeatedDateList = [].concat(response.data.data.repeated_dates_arr);
              setTimeout(() => {
                var recentScrlHeight = elmntList.scrollHeight
                elmntList.scrollTop = recentScrlHeight - yScrollHeight
              }, 10)
            }
          }).catch((err) => {
          })
        }
      }
    });
  },
  methods: {
    /*
    * This Will Scroll Bottom Of ChatBox
    */
    chatScrollBottomMethod() {
      var elmnt = document.getElementById("chat_msg_list_scroll");
      var y = elmnt.scrollHeight;
      var scrollH = elmnt.clientHeight;
      var scrollTop = elmnt.scrollTop
      var FullSH = scrollH + scrollTop
      var scrollD = y - FullSH
      if (parseInt(60) > scrollD) {
        $('#chat_msg_list_scroll').animate({ scrollTop: y }, 20)
      }
    },
    newline() { },
    send() { },
    enabledYearDateFun(msgDateTime) {
      var currentLocalDate = moment().format('YYYY');
      var startDateCheck = moment(msgDateTime).format('YYYY')
      if (currentLocalDate > startDateCheck) {
        return true;
      } else {
        return false;
      }
    },
    createRoomWithPatientMethod() {
      const authToken = localStorage.getItem('token');
      this.$store.dispatch('getChatRoomDetailForPatientMethod', { id: this.patientChatRoomId }).then((response) => {
        this.msgNextPageLoader = response.data.data.next
        this.patientChatDetailResults = [].concat(response.data.data.results)
        this.chatScrollAlwaysBottomMethod()
        // Chat With patient Socket Init
        var __self = this
        var messagesList = document.getElementById('reply_msg_list')
        patientChatScoket = new WebSocket(
          'wss://' + process.env.VUE_APP_WEBSOCKET_BASE_URL +
          '/ws/chat/' + this.patientChatRoomId + '/?token=' + authToken
        );

        // Action On Live Socket
        setTimeout(() => {
          var timeout;
          // Message Send Features Code Here
          document.querySelector('#chat-message-reply-input').onkeyup = function (event) {
            var messageInputDom = document.querySelector('#chat-message-reply-input');
            if (__self.selection_type == 'press_enter') {
              __self.msgValue = messageInputDom.value;
              if (event.keyCode === 13) {
                if (event.shiftKey) {
                  event.stopPropagation();
                } else if (!event.shiftKey) {
                  event.preventDefault();
                  document.querySelector('#chat-message-reply-submit').click();
                }
              } else {
                //   if (messageInputDom.value.length > 0) {
                //     videoSocketRef.emit({
                //       type: "TypingEventForSelectedUser",
                //       message: 'typing',
                //       selfUserID:selfUserId,
                //       targetForChatUserId:patientId,
                //       second_user_id: patientId
                //     });
                //     clearTimeout(timeout)
                //     timeout = setTimeout(() => {
                //       videoSocketRef.emit({
                //         type: "TypingEventForSelectedUser",
                //         message: 'stop',
                //         selfUserID:selfUserId,
                //         targetForChatUserId: patientId,
                //         second_user_id: patientId
                //       })
                //     }, 2000)
                //   }
              }
            }
          };
          document.querySelector('#chat-message-reply-input').focus();
          document.querySelector('#chat-message-reply-submit').onclick = function (e) {
            var messageInputDom = document.querySelector('#chat-message-reply-input');
            __self.msgValue = messageInputDom.value;
            var message = __self.msgValue;
            var msg = message.replace(/</g, "&lt;").replace(/>/g, "&gt;").trim();
            if (msg !== "") {
              const currentUserId = localStorage.getItem('LoginedUserID');
              // const payload = {
              //   toUserID: sentUserInfo.id,
              //   second_user_id: sentUserInfo.id,
              //   fromUserID: currentUserId,
              //   chatRoomId: createdRoomChatId,
              //   senderMsgData: message,
              //   msgSenderFullName: __self.selfUserInfo.first_name + ' ' + __self.selfUserInfo.last_name
              // };
              let sentData = {
                'content': message,
                //   email: sentUserInfo.email,
                //   senderID: sentUserInfo.id,
                //   name: sentUserInfo.first_name + ' ' + sentUserInfo.last_name,
                //   selfUserID: __self.selfUserId,
                //   is_read: false
              }
              // videoSocketRef.emit({
              //   type: "TypingEventForSelectedUser",
              //   message: 'stop',
              //   selfUserID:selfUserId,
              //   targetForChatUserId: patientId,
              //   second_user_id: patientId
              // })
              // clearTimeout(timeout)
              patientChatScoket.send(JSON.stringify({ "type": "chat_message", "message": sentData }))
              // let socketTimeout = setTimeout(function () {
              //   bus.$emit('newChatMessage', payload);
              //   clearTimeout(socketTimeout);
              // }, 2000);
              messageInputDom.value = '';
              __self.msgValue = ''
            }
          };
        }, 200)
        // const [key, value] = Object.entries(this.patientChatDetailResults.slice(-1)[0]).pop();
        // console.log(value.slice(-1)[0])
        // for(const [key, value] of Object.entries(this.patientChatDetailResults)) {
        //   console.log(value.slice(-1)[0])
        // }
        // if (this.patientChatDetailResults.results){
        //   this.socketMsgReadFun(this.patientChatDetailResults[0].value.slice(-1)[0].id, chatForSelectedUserId)
        // }

        // Message Checking ON Live Socket
        patientChatScoket.onmessage = function (e) {
          var data = JSON.parse(e.data);
          // console.log(data, "Checking")
          var pchatmsg_recieved = document.createElement("div");
          var pchat_msg_item = document.createElement("div");
          var msg_time_wrp = document.createElement("span");
          var msg_time = document.createElement("time");
          msg_time_wrp.setAttribute("class", 'msg_time_wrp');
          msg_time.setAttribute("class", 'msg_time');
          pchat_msg_item.appendChild(document.createTextNode(data.decrypted_content));
          let dateTimeAdd = [moment(data.timestamp).format('h:mm a')];
          msg_time.appendChild(document.createTextNode(dateTimeAdd));
          msg_time_wrp.appendChild(msg_time);
          pchat_msg_item.appendChild(msg_time_wrp);
          pchatmsg_recieved.appendChild(pchat_msg_item);
          if (data.sender.user_id == __self.selfUserId) {
            pchatmsg_recieved.setAttribute("class", 'append_pchatmsg pchatmsg_recieved');
            pchat_msg_item.setAttribute("class", "pchat_msg_item pchat_recieved_msg");
          } else {
            pchatmsg_recieved.setAttribute("class", 'append_pchatmsg pchatmsg_sent');
            pchat_msg_item.setAttribute("class", "pchat_msg_item pchat_send_msg");
          }
          messagesList.appendChild(pchatmsg_recieved);
          __self.chatScrollAlwaysBottomMethod()
        }
        patientChatScoket.onclose = function (e) {
          console.error("Socket Closed")
        }
      })
    },
    chatScrollAlwaysBottomMethod() {
      setTimeout(() => {
        var elmnt = document.getElementById("chat_msg_list_scroll");
        var y = elmnt.scrollHeight;
        $('#chat_msg_list_scroll').animate({ scrollTop: y }, 20)
      }, 100)
    },
    chatClosedMethod() {
      bus.$emit('chatInitWithPatientBus', true)
      if (patientChatScoket && patientChatScoket.readyState) {
        patientChatScoket.close();
      }
    }
  },
  watch: {
  }
}
</script>

<style lang="scss" scoped>
.patient_with_chat_box {
  position: relative;
  border: 1px solid #232323;

  .patient_with_chat_box_header {
    background: #111111;
    padding: 10px 15px;
  }

  .consult_patient_avatar {

    // width: 40px;
    // border-radius: 50%;
    // height: 40px;
    // -o-object-fit: cover;
    // object-fit: cover;
    // -o-object-position: top center;
    // object-position: top center;
    .prfl_avatar_lazy_load_box {
      position: relative;
      width: 40px;
      height: 40px;
      overflow: hidden !important;
      border-radius: 50%;
      border: 2px solid #c4c4c4;
      display: inline-block;
    }
  }

  .consult_patient_name {
    color: #ffffff;
    line-height: 1;
    font-size: 20px;
  }

  .patient_with_chat_box_body {
    min-height: calc(100vh - 302px);
    max-height: calc(100vh - 302px);
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
    position: relative;
  }

  .message_input_wrp {
    display: flex;

    textarea {
      font-family: "proxima-nova", "Source Sans Pro", sans-serif;
      border: none;
      width: calc(100% - 50px);
      padding: 10px 22px;
      font-size: 15px;
      color: #fff;
      background: #111;
      line-height: 1.3;
      height: 70px;
      outline: none;
      resize: none;
      -ms-overflow-style: none;
      overflow: -moz-scrollbars-none;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    button {
      background: #232323;
      color: #ffffff;
      height: 100%;
      border: none;
      width: 100%;

      &:hover {
        background: #87cefa;
        color: #000;
      }

      &:hover path {
        fill: #000000;
      }
    }

    .option_btn_wrp {
      width: 50px;
    }
  }

  .patient_chat_msg_list_wrp {
    position: absolute;
    top: 0;
    z-index: 100;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 8px;
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #333333;
    }
  }

  .extra_pchat_space {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
  }

  .list_msg_of_patient_chat {
    ul {
      margin: 0px;
      padding: 0;
      list-style-type: none;
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column-reverse;

      li {
        width: 100%;
        padding: 5px 0;

        .pchatmsg_recieved {
          text-align: right;
        }
      }
    }

    .pchat_msg_item {
      position: relative;
      display: inline-block;
      padding: 5px 10px;
      word-break: break-word;
      white-space: normal;
      font-weight: 500;

      &:after {
        position: absolute;
        content: "";
        width: 0;
        height: 0;
        border-style: solid;
      }

      &.pchat_recieved_msg {
        background: #ffb6c1;
        color: #000;
        border-radius: 7px 0px 7px 7px;

        &:after {
          border-width: 0px 0 8px 6px;
          border-color: transparent transparent transparent #ffb6c1;
          top: 0;
          right: -5px;
        }
      }

      &.pchat_send_msg {
        background: #b1d4e5;
        color: #000;
        border-radius: 0px 7px 7px 7px;

        &:after {
          border-width: 0px 6px 8px 0;
          border-color: transparent #b1d4e5 transparent transparent;
          top: 0;
          left: -5px;
        }
      }

      .msg_time_wrp {
        display: block;
        text-align: right;
        text-transform: uppercase;
        margin-top: 2px;
        line-height: 1;
        font-size: 10px;

        .msg_time {
          font-size: 10px;
          line-height: 1;
          color: rgba(0, 0, 0, 0.5);
          font-weight: 500;
          display: inline-block;
          vertical-align: middle
        }
      }
    }
  }

  .btn-danger {
    font-family: 'AceSansDemo';
    font-size: 17px;
    background: #ff3a30;
    border: none !important;
    padding: 8px 10px;
    line-height: 1;
    outline: none !important;
    box-shadow: none !important;

    @media only screen and (max-width:767px) {
      font-size: 14px;
      font-weight: 600;
    }
  }

  .btn-primary{
    padding: 10px 30px !important;
    font-size: 17px;
    border-radius: 50px;
    background: none !important;
    color: #ffd400 !important;
    border: none;
    text-transform: capitalize;
    font-weight: 400;
    letter-spacing: .03em;
    line-height: 1;
    transition: all .5s ease;
    -webkit-transition: all .5s ease;
    -moz-transition: all ease .5s;
    box-shadow: none !important;
    outline: none;
    border: 2px solid #ffd400 !important;
    font-weight: 500;
    &:hover{
      background: #ffd400 !important;
      color: #000000 !important;
    }
    @media only screen and (max-width:767px){
      padding: 7px 20px !important;
      font-size: 16px;
    }
  }
  .msg_date_wrp {
    text-align: center;
    margin: 15px 0;
    position: relative;

    &:before {
      content: "";
      height: 1px;
      background: hsla(0, 0%, 100%, .28);
      position: absolute;
      left: -25px;
      right: -25px;
      top: 50%;
      transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
    }

    span.msg_date_box {
      background: #ffc107;
      color: #000;
      border-radius: 3px;
      padding: 2px 6px !important;
      z-index: 100;
      font-weight: 500;
      font-size: 15px;
      line-height: 1;
      position: relative;
    }
  }

  #reply_msg_list {
    padding: 10px 35px;

    @media only screen and (max-width:767px) {
      padding: 10px 25px;
    }
  }

  #list_of_patient_chat_msg_wrp {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }

  .fixloader.msgloader {
    position: absolute;
    top: 0;
    width: 150px;
    height: 70px;
    left: 0;
    right: 0;
    margin: 0 auto;
    background: #000000;
    z-index: 100;
  }

  .fixloader.msgloader .spinner {
    width: 65px;
    height: 65px;
  }

  .fixloader.msgloader .spinner::after {
    width: 35px;
    height: 35px;
  }

  .fixloader.msgloader .spinner::before {
    width: 65px;
    height: 65px;
  }
}
</style>