<template>
    <div class="blurb_text_popup">
        <div class="modal-backdrop"></div>
        <div class="modal modal-open unsupported-browser">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-body text-center">
                        <h3>Before Uploading a Digital Prescription</h3>
                        <p class="text-justify">By clicking on the Ok button, you confirm that you are satisfied with the teleconsultation appointment and details provided by the patient. You may then upload a digital prescription for your patient.</p>
                        <p class="text-justify">If you are dissatisfied with the details provided by the patient or with the session and want to avoid posting a digital prescription, then you may click the Close button and be redirected to your dashboard.</p>
                        <div class="btn_group">
                            <button class="btn btn-success" @click="addPrescriptionPopupMethod">Ok</button>
                            <button class="btn btn-danger ml-4" @click="blurbTextPopupClosed">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
/*eslint-disable */
import {bus} from '@/main';

export default {
  data () {
    return {
    }
  },
  computed: {

  },
  mounted () {
  },
  methods: {
    blurbTextPopupClosed(){
      bus.$emit('prescriptionAddBlurbPopupStateBus', false)
      document.body.classList.remove('body_right_scroll_remove');
    },
    addPrescriptionPopupMethod(){
      bus.$emit('prescriptionAddBlurbPopupStateBus', false)
      bus.$emit('addPrescriptionPopupBus', true)
    },
  }
}
</script>

<style lang="scss" scoped>
.blurb_text_popup {
    a:hover {
        text-decoration: none;
    }

    .modal-backdrop {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1040;
        background-color: #000;
        opacity: .92;
    }

    .unsupported-browser {
        &.modal {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1050;
            overflow: hidden;
            outline: 0;

            &.modal-open {
                overflow-x: hidden;
                overflow-y: auto;
                display: block;
            }

            .modal-dialog {
                position: relative;
                width: auto;
                margin: .5rem;
                pointer-events: none;

                @media (min-width: 730px) {
                    max-width: 730px;
                    margin: 1.75rem auto;
                }

                &.modal-dialog-centered {
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    align-items: center;
                    min-height: calc(100% - (.5rem * 2));

                    @media (min-width: 576px) {
                        min-height: calc(100% - (1.75rem * 2));
                    }
                }

                .modal-content {
                    position: relative;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-orient: vertical;
                    -webkit-box-direction: normal;
                    -ms-flex-direction: column;
                    flex-direction: column;
                    width: 100%;
                    pointer-events: auto;
                    background-color: #000000;
                    background-clip: padding-box;
                    border: 1px solid rgba(255, 255, 255, .2);
                    border-radius: .3rem;
                    outline: 0;
                    box-shadow: -1px -1px 18px rgba(251, 247, 165, 0.3);

                    .modal-body {
                        padding: 25px;

                        @media (max-width: 575px) {
                            padding: 15px;
                        }

                        h3 {
                            color: #ffc800;
                            font-weight: 600;
                            letter-spacing: 1px;
                            line-height: 1.6;
                            font-size: 20px;
                            text-transform: uppercase;
                            margin-bottom:25px;

                            @media (max-width: 575px) {
                                font-size: 18px;
                                line-height: 1.3;
                                margin-bottom:15px;
                            }
                        }

                        p {
                            letter-spacing: 1px;
                            color: #ffffff;
                            font-weight: normal;
                            font-size: 18px;
                            font-family: 'MyriadProRegular', 'Lato', sans-serif;
                            line-height: 1.3;

                            @media (max-width: 575px) {
                                font-size: 14px;
                            }
                        }

                        a {
                            color: #2baeff;

                            &:hover {
                                text-decoration: none;
                            }
                        }

                        .btn {
                            line-height:1;
                            padding:8px 10px;
                            min-width: 80px;
                        }
                        .btn_group{margin-top:30px;
                            @media (max-width: 575px) {margin-top:20px;}
                        }
                    }
                }
            }
        }
    }
}
</style>