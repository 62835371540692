<template>
    <div class="consent_modal_popup">
        <div class="consent_modal_popup_scroll">
            <div class="consent_modal_popup_center">
                <div class="consent_modal_box">
                    <div class="consent_modal_body">
                        <h2 class="popup_heading">Consent Form Details </h2>
                            <div class="form-group">
                                <p>As the caregiver, I, <label class="tab_label">[{{ userName }}]</label> , am entrusted with the responsibility of
                                    <label class="tab_label">
                                     [{{ selectedRelation }}]
                                    </label> . I have received the consent from <label class="tab_label">[{{ patientName }}]</label> , which empowers me to
                                    act on their behalf. </p>
                                <p>Upon receiving the consent, I am deemed authorized to teleconsult and divulge medical
                                    records, history and other personal information important for the teleconsulting
                                    session with the doctor/clinician/professional on LBIL (<a href="https://lbil.ai/" target="_blank">www.lbil.ai</a>) on behalf of
                                    <label class="tab_label">({{ patientName }})</label>. </p>
                                <p>As a caregiver, I am taking full responsibility for disclosing the medical records,
                                    history and personal information of <label class="tab_label">({{ patientName }})</label>. </p>
                            </div>
                            <div class="form-group">
                                <h6>Please select the scenario below that best describes your reason for teleconsulting
                                    on behalf of <label class="tab_label">({{ patientName }})</label>. This will help us understand the context of the
                                    teleconsultation.</h6>
                            </div>
                            <div class="form-group">
                                <label class="cstm_radio_label" for="case1" :class="selectedOption == 'case1' ? 'selected' : ''"><span>I further declare that <label class="tab_label">({{ patientName }})</label> is mentally and physically incapacitated due to <label class="tab_label" v-if="reason">({{ reason }})</label><label v-else class="tab_label">(state the specific reason)</label> , and, therefore, will need my  assistance to teleconsult on their behalf.</span></label>
                                <p>OR</p>
                                <label class="cstm_radio_label" for="case2" :class="selectedOption == 'case2' ? 'selected' : ''"><span> I further declare that <label class="tab_label">({{ patientName }})</label> is a minor (18 years and under);therefore, I have authorization to teleconsult on their behalf. </span></label>
                                <p>OR</p>
                                <label class="cstm_radio_label" for="case3" :class="selectedOption == 'case3' ? 'selected' : ''"> <span> I further declare that <label class="tab_label">({{ patientName }})</label> would like me to teleconsult on their behalf and has provided written consent in a sound state of mind. I understand that the doctor/clinician/professional may request this written consent during the
                                    teleconsulting session. </span></label>
                            </div>
                            <div class="form-group">
                                <p>Finally, I understand that Lymphomap Biotech India Pvt. Ltd. (LBIL) and Lymphomap
                                    Inc. (the Companies that own <a href="https://lbil.ai/" target="_blank">www.lbil.ai</a>) are not liable for any fraudulent means of
                                    data exchange of information or invasion of privacy of <label class="tab_label">({{ patientName }})</label> I’ll be
                                    teleconsulting on behalf of and that this e-consent form acknowledges the
                                    authorization of me <label class="tab_label">({{ userName }})</label> , teleconsulting on <label class="tab_label">({{ patientName }})</label> behalf.
                                </p>
                            </div>
                            <div class="form-group">
                                <p class="mb-0">Signature of Caregiver</p>
                                <h5 style="text-transform: uppercase;">{{ userName }}</h5>
                            </div>
                            <div class="form-group">
                                <p class="mb-0">Email Address of Caregiver </p>
                                <h5>{{ userEmail }}</h5>
                            </div>
                            <div class="form-group">
                                <p class="mb-0">Date of when the consent form is being signed </p>
                                <h5>{{ date | LocalDateFormat }}</h5>
                            </div>
                            <div class="btn_group text-center">
                                <button class="btn btn-success ml-0" @click="consentFormPopupOpenMethod">OK</button>
                            </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
/*eslint-disable */

import { bus } from '@/main';

export default {
  components: {
  },
  props: ['consentId'],
  data() {
    return {
      reason:'',
      relationship: '',
      patientName: '',
      userName:'',
      selfUserId:'',
      date:new Date(),
      userEmail:'',
      selectedOption:'',
      selectedRelation:'',
    }
  },
  computed: {

  },
  mounted() {
    this.$store.dispatch('consentFormInfoByIdAction', {id:this.consentId}).then((res) => {
        let consentData = res.data.data;
        this.userName = consentData.user_name;
        this.userEmail = consentData.user_email;
        this.selectedRelation = consentData.relationship;
        this.patientName = consentData.patient_name;
        if(consentData.case_1){
            this.selectedOption = 'case1';
            this.reason = consentData.case_1_reason
        }
        if(consentData.case_2){
            this.selectedOption = 'case2';
        }
        if(consentData.case_3){
            this.selectedOption = 'case3';
        }
    })
  },
  methods: {
    consentFormPopupOpenMethod(){
      document.body.classList.remove('body_right_scroll_remove');
      bus.$emit('consentFormPopupStateBus', false)
    },
  }
}
</script>
<style lang="scss" scoped>
.consent_modal_popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    z-index: 11;
    background: rgba($color: #000000, $alpha: 0.92);

    .consent_modal_popup_scroll {
        overflow-y: auto;
        height: 100svh;

        &::-webkit-scrollbar {
            width: 8px;
            background: transparent;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #333333;
        }
    }

    .consent_modal_popup_center {
        color: #ffffff;
        font-family: 'MyriadProRegular', sans-serif;
        display: table;
        position: relative;
        width: 100%;
        height:100%;
    }

    .consent_modal_box {
        margin: 0;
        display: table-cell;
        vertical-align: middle;
    }

    .consent_modal_body {
        background: #000;
        max-width: 860px;
        width: 100%;
        margin: 0px auto;
        border-radius: 5px;
        padding: 20px 25px;
        font-family: 'MyriadProRegular', 'Lato', sans-serif;
        color: #ffffff;
        box-shadow: 3px 3px 3px rgba(255, 255, 255, 0.04), -3px -3px 3px rgba(255, 255, 255, 0.04);
        .popup_heading {
            font-size: 28px;
            color: #ffd400;
            font-family: 'MyriadProRegular', 'Lato', sans-serif;
            margin-bottom: 20px;

            @media only screen and (max-width:767px) {
                font-size: 18px;
            }
        }
        label.tab_label {
            display: inline !important;
            margin: 0;
            background: rgba(255, 255, 255, 0.1);
            line-height: 1;
            color: #888;
            padding: 1px 5px;
            border-radius: 2px;
            vertical-align: middle;
            word-wrap: break-word;
        }
        p{font-size:16px;color:#999999;line-height:1.6;}
        h6{font-size:16px;color:#999999;font-weight:600;}
        h5{font-size:18px;color:#ffffff;}
        a{color:#87cefa;text-decoration:none;&:hover{color: #ffe400}}
        .cstm_radio_label{
            border:2px solid #ffd400;
            border-radius:3px;
            padding:5px 10px;
            margin: 10px 0 20px;
            cursor:pointer;
            &.selected{
                background:#ffd400;
                color:#000000;
                .tab_label{background:#000000;}
                .form-control {background:#000000;}
            }
        }
    }

    .btn-danger {
        font-size: 16px;
        background: #ff7070;
        color: #fff;
        font-family: 'MyriadProRegular', 'Lato', sans-serif;
        padding: 10px 5px;
        min-width: 100px;
        line-height: 1;
        border-radius: 3px !important;
        outline: none !important;
        box-shadow: none !important;

        &:hover {
            background: #b41818;
            color: #fffcc4;
        }
    }

    .btn-success {
        font-size: 16px;
        background: #459238;
        color: #fff;
        font-family: 'MyriadProRegular', 'Lato', sans-serif;
        padding: 10px 5px;
        min-width: 100px;
        line-height: 1;
        margin-left: 10px;
        border-radius: 3px !important;
        outline: none !important;
        box-shadow: none !important;

        &:hover {
            background: #87cefa;
            color: #22292f;
        }
    }
    .form-control {
        background: rgba(255, 255, 255, 0.1);
        border: none;
        color: #ffffff;
        font-size: 16px;
        display: inline-block;
        width: auto;
        box-shadow: none !important;
        padding: 2px 10px;
        height: auto;
        option{color:#000000;}


        &::-webkit-input-placeholder {
            color: #686868;
        }

        &::-moz-placeholder {
            color: #686868;
        }

        &::-ms-input-placeholder {
            color: #686868;
        }

        &:focus::-webkit-input-placeholder {
            font-size: 0px;
        }

        &:focus::-moz-placeholder {
            font-size: 0px;
        }

        &:focus::-ms-input-placeholder {
            font-size: 0px;
        }
    }
}
</style>