/*eslint-disable */

import { bus } from '@/main';
import toast from '@/services/toast';

let mediaRecorder;
let recordedBlobs;

export default {
  name: 'video-recording-data',
  props: ['RecordForDoctorId'],
  data() {
    return {
      recordButton: 'Start Recording',
      errorMsgElement: '',
      downloadRecordVideo: false,
      storeStreamData: false,
      vidTrack: {},
      isRecording: false,
      recordingDuration: 0,
      timerInterval: null,
      isSubmitted:false
    }
  },
  computed: {
    formattedDuration() {
      const minutes = Math.floor(this.recordingDuration / 60);
      const seconds = this.recordingDuration % 60;
      return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    }
  },
  mounted() {
    this.videoInitMethod();
  },
  methods: {
    async videoInitMethod() {
      const constraints = {
        audio: {
          echoCancellation: { ideal: true }
        },
        video: {
          width: { ideal: 854 },
          height: { ideal: 480 }
        }
      };
      await this.init(constraints);
    },
    startRecording() {
      recordedBlobs = [];
      let options = { mimeType: 'video/webm;codecs=vp9,opus' };
      
      if (!MediaRecorder.isTypeSupported(options.mimeType)) {
        console.log(`${options.mimeType} is not supported`);
        options = { mimeType: 'video/webm;codecs=vp8,opus' };
        if (!MediaRecorder.isTypeSupported(options.mimeType)) {
          console.log(`${options.mimeType} is not supported`);
          options = { mimeType: 'video/webm' };
          if (!MediaRecorder.isTypeSupported(options.mimeType)) {
            console.log(`${options.mimeType} is not supported`);
            options = { mimeType: '' };
          }
        }
      }

      try {
        mediaRecorder = new MediaRecorder(window.stream, options);
      } catch (e) {
        console.error('Exception while creating MediaRecorder:', e);
        this.errorMsgElement = `Exception while creating MediaRecorder: ${JSON.stringify(e)}`;
        return;
      }

      this.recordButton = 'Stop Recording';
      this.downloadRecordVideo = false;
      this.isRecording = true;
      this.recordingDuration = 0;
      this.startTimer();

      mediaRecorder.onstop = (event) => {
        console.log('Recorder stopped: ', event);
        this.stopTimer();
      };
      mediaRecorder.ondataavailable = this.handleDataAvailable;
      mediaRecorder.start(10); // Collect 10ms of data
    },
    stopRecording() {
      if (mediaRecorder && mediaRecorder.state !== 'inactive') {
        mediaRecorder.stop();
        this.downloadRecordVideo = true;
        this.isRecording = false;
        this.stopTimer();
      }
    },
    startTimer() {
      this.timerInterval = setInterval(() => {
        this.recordingDuration++;
      }, 1000);
    },
    stopTimer() {
      clearInterval(this.timerInterval);
    },
    uploadVideoOnDeviceMethod() {
      const blob = new Blob(recordedBlobs, { type: 'video/webm' });
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const videoBase64 = reader.result;
        const sendVideoBlob = videoBase64.replace(/^data:video\/(webm);base64,/, "");
        
        const data = {
          doctor_video_file: sendVideoBlob
        };
        this.isSubmitted = true
        this.$store.dispatch('updateVideoForPatientMethod', { data: { id: this.RecordForDoctorId, data: data } })
          .then(() => {
            return this.$store.dispatch('getconsultPatientDetailByIdAction', { id: this.RecordForDoctorId });
          })
          .then(() => {
            toast.success("Request processed successfully.");
            this.isSubmitted = false;
            this.closeVideoRecordPopupMethod();
          })
          .catch((err) => {
            this.isSubmitted = false;
            toast.error(err.data.msg);
          });
      };
    },
    async handleSuccess(stream) {
      window.stream = stream;
      const gumVideo = this.$refs.gumVideo;
      if (gumVideo) {
        gumVideo.srcObject = stream;
      }
      this.storeStreamData = true;
    },
    async init(constraints) {
      try {
        const stream = await navigator.mediaDevices.getUserMedia(constraints);
        this.vidTrack = stream;
        await this.handleSuccess(stream);
      } catch (e) {
        console.error('navigator.getUserMedia error:', e);
        this.errorMsgElement = `navigator.getUserMedia error: ${e.toString()}`;
      }
    },
    handleDataAvailable(event) {
      if (event.data && event.data.size > 0) {
        recordedBlobs.push(event.data);
      }
    },
    startRecordingButtonMethod() {
      if (!this.isRecording) {
        this.startRecording();
      } else {
        this.stopRecording();
        this.recordButton = 'Start Recording';
      }
    },
    downloadVideoOnDeviceMethod() {
      const blob = new Blob(recordedBlobs, { type: 'video/webm' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = 'recorded_video.webm';
      document.body.appendChild(a);
      a.click();
      setTimeout(() => {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }, 100);
    },
    closeVideoRecordPopupMethod() {
      document.body.classList.remove('popup_open_body');
      bus.$emit('videRecordStateBus', false);
      
      this.stopRecording();
      
      if (window.stream) {
        window.stream.getTracks().forEach(track => track.stop());
      }
      
      if (this.$refs.gumVideo) {
        this.$refs.gumVideo.srcObject = null;
      }
      
      this.storeStreamData = false;
      this.vidTrack = {};
      window.stream = null;
      this.recordingDuration = 0;
      this.isRecording = false;
    }
  }
}