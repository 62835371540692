<template>
    <div class="add_pre_of_patient_popup prescription_info_modal_wrp">
            <div class="add_pre_of_patient_modal_scroll">
                <div class="add_pre_of_patient_modal_center">
                    <div class="add_pre_of_patient_modal_box">
                        <div class="add_pre_of_patient_modal">
                            <div class="add_pre_of_patient_popup_header">
                                <div class="row align-item-center">
                                    <div class="col">
                                        <h3 class="add_pre_of_patient_popup_heading">Prescription Info</h3>
                                    </div>
                                    <div class="col-auto ml-auto">
                                        <button class="btn btn-danger" @click="closePrescriptionInfoPopupMethod">Close</button>
                                    </div>
                                </div>
                            </div>
                            <div class="add_pre_of_patient_popup_body">
                                <div class="prescription_table_list_wrp mt-4">
                                    <div class="row">
                                        <div class="col-sm-12 text-right">
                                            <h5 class="mb-3" style="font-weight:400;color:#feffde;">Date :
                                                {{ prescriptionInfoByIdData.created_at |
                                                    LocalDateFormat }} at {{ prescriptionInfoByIdData.created_at |
                                                    LocalTimeFormat }}</h5>
                                        </div>
                                        <div class="col-sm-12" v-if="prescriptionInfoByIdData.special_instruction">
                                            <p style="font-size: 18px;color: #feffdd;">
                                                {{ prescriptionInfoByIdData.special_instruction }}</p>
                                        </div>
                                    </div>
                                    <table class="table table-striped">
                                        <thead>
                                            <tr>
                                                <th>Drug Name</th>
                                                <th class="text-center">Strength</th>
                                                <th class="text-center">Duration</th>
                                                <th class="text-center">Frequency <br> (MN - AF - EN - NT)</th>
                                                <th class="text-center">Quantity</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(item, index) in prescriptionInfoByIdData.prescription_details"
                                                :key="index">
                                                <td>{{ item.drug }}</td>
                                                <td class="text-center">{{ item.strength }}</td>
                                                <td class="text-center">{{ item.duration }}</td>
                                                <td class="text-center">
                                                    <template v-if="item.frequency_others">
                                                        {{ item.frequency_others }}
                                                    </template>
                                                    <template v-else>
                                                        <template v-if="item.frequency.includes('Morning')">1</template>
                                                        <template v-else>0</template> -
                                                        <template v-if="item.frequency.includes('Afternoon')">1</template>
                                                        <template v-else>0</template> -
                                                        <template v-if="item.frequency.includes('Evening')">1</template>
                                                        <template v-else>0</template> -
                                                        <template v-if="item.frequency.includes('Night')">1</template>
                                                        <template v-else>0</template>
                                                    </template>
                                                </td>
                                                <td class="text-center">{{ item.quantity }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
    </div>
</template>

<script>
/*eslint-disable */
import {bus} from '@/main';

export default {
  name: 'pre-info-popup',
  components: {
  },
  props: ['prescriptionInfoByIdData'],
  data () {
    return {
    }
  },
  computed: {

  },
  mounted () {
  },
  methods: {
    closePrescriptionInfoPopupMethod(){
        bus.$emit('prescriptionInfoByIdPopupBus', false)
       document.body.classList.remove('body_right_scroll_remove');
    }
  }
}
</script>

<style lang="scss" scoped>
.add_pre_of_patient_popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 2000;
    background: rgba(0, 0, 0, 0.92);
    bottom: 0;
    .add_pre_of_patient_modal_center {
        display: table;
        width: 100%;
        height: 100%;
    }
    .add_pre_of_patient_modal_box{
        display:table-cell;
        vertical-align:middle;
    }
    .add_pre_of_patient_modal_scroll {
        height:100svh;
        overflow-y:auto;
        &::-webkit-scrollbar {
        width:4px;
        background: none;
        }
        &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #2f2f2f;
        }
    }
    .add_pre_of_patient_modal {
        background: #000;
        border-radius: 5px;
        box-shadow: 0px 0px 5px rgba(255, 255, 238, 0.3);
        width: 100%;
        max-width: 940px;
        margin: 0 auto;
    }

    .add_pre_of_patient_popup_header {
        background: #111;
        padding: 10px 15px;

        .add_pre_of_patient_popup_heading {
            font-size: 22px;
            font-weight: 600;
            margin: 0;
            text-transform: uppercase;

            @media only screen and (max-width:767px) {
                font-size: 18px;
            }
        }
    }

    .add_pre_of_patient_popup_body {
        padding: 20px 15px;
    }

    .label_checkbox_wrp label {
        display: block;
    }

    .form-control {
        background: none;
        outline: none !important;
        box-shadow: none !important;
        border: none;
        border-bottom: 1px solid #6f6f6f;
        border-radius: 0;
        padding: 0px 5px;
        color: #b7b7b7;
        font-size: 18px;

        option {
            color: #000000;
        }

        @media only screen and (max-width:767px) {
            font-size: 16px;
        }
    }

    .spcl_ins_modal {
        padding: 25px;

        label {
            font-size: 24px !important;
            margin-bottom: 15px !important;
        }

        .form-control {
            border: 1px solid #6f6f6f;
            border-radius: 2px;
            padding: 10px;
        }
    }

    select.form-control {
        text-transform: capitalize;
    }

    .btn {
        padding: 8px 15px;
        min-width: 90px;
        box-shadow: none !important;
        line-height:1;
    }

    .form-group>label {
        font-size: 18px;
        margin-bottom: 10px;

        @media only screen and (max-width:767px) {
            font-size: 16px;
            margin-bottom: 5px;
            line-height: 1;
        }
    }

    .prescription_table_list_wrp {
        .table {
            border-color: #6f6f6f;

            thead th {
                border-bottom: 0px;
                border-color: #6f6f6f;
                color: #f5f5f5;
                font-weight: 500;
                font-size: 18px;
                text-transform: uppercase;
            }

            tbody td {
                border-color: #6f6f6f;
                color: #b7b7b7;
                font-size: 18px;
            }

            .fa {
                font-size: 18px;
                cursor: pointer;
                color: #ff3a30;

                &:hover {
                    color: #ffd400;
                }
            }
        }
    }

    .prescription_creation_done_wrp {
        padding: 50px 15px;

        h4 {
            font-size: 24px;
            font-weight: 500;
            margin-bottom: 50px;
        }
    }
}
</style>