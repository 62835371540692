import { render, staticRenderFns } from "./prescription-info-popup.vue?vue&type=template&id=de2a2c7c&scoped=true"
import script from "./prescription-info-popup.vue?vue&type=script&lang=js"
export * from "./prescription-info-popup.vue?vue&type=script&lang=js"
import style0 from "./prescription-info-popup.vue?vue&type=style&index=0&id=de2a2c7c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "de2a2c7c",
  null
  
)

export default component.exports